// (c) 2024 Cofense Inc.
import axios, { AxiosError, AxiosResponse } from 'axios';
import { Toast, koi } from '@koi/vue-library';
import { nanoid } from 'nanoid';
import { i18n } from '@/i18n';
import { router } from '@/router';
import { deserializeErrors } from '@/services/api/formatters/json-api';
import { JsonApiErrorObject } from '@/interfaces/Error';
import { store } from '@/store';

const dispatchToast = (
  { title, message, autoDismiss }: Pick<Toast, 'title' | 'message' | 'autoDismiss'>,
) => {
  router.app.$koi.toast({
    id: nanoid(),
    intent: 'error',
    title,
    message,
    duration: 7500,
    autoDismiss,
  });
};

interface KoiCopy extends koi {
  toasts: Toast[]
}

export const onResponseFailure = async (reason: AxiosError<any, any>): Promise<unknown> => {
  const isProtectedRoute = router.currentRoute?.meta?.isProtectedRoute;
  const isUserLoggedIn = () => store.getters['user/isUserLoggedIn'];

  if (axios.isCancel(reason)) {
    return Promise.reject(reason);
  }

  const response = (reason as AxiosError)?.response || { status: 0 };
  const { status, data } = response as AxiosResponse;
  const errors = data?.errors;

  if ((status === 403 && errors.find((err: JsonApiErrorObject) => err.code === 'E403'))) {
    dispatchToast({
      title: i18n.t(`api.errors.${status}.title`).toString(),
      message: errors.find((err: JsonApiErrorObject) => err.detail)?.detail
        || i18n.t(`api.errors.${status}.description`).toString(),
    });
  }
  if (status === 0) {
    dispatchToast({
      title: i18n.t(`api.errors.${status}.title`).toString(),
      message: i18n.t(`api.errors.${status}.description`).toString(),
    });
  }
  if (status === 500 && !(router.app.$koi as KoiCopy).toasts?.some(({ title }) => title === i18n.t('api.errors.500.title').toString())) {
    dispatchToast({
      title: i18n.t(`api.errors.${status}.title`).toString(),
      message: i18n.t(`api.errors.${status}.description`).toString(),
      autoDismiss: false,
    });
  }

  if (status === 401 && isProtectedRoute && isUserLoggedIn()) {
    await router.push('/logout');
  } else if (status === 401 && errors.find((err: JsonApiErrorObject) => err.code === 'E401')) {
    dispatchToast({
      title: i18n.t(`api.errors.${status}.title`).toString(),
      message: i18n.t(`api.errors.${status}.description`).toString(),
    });
  }

  if (status === 403 && errors.find((err: JsonApiErrorObject) => err.code === 'E420')) {
    window.location.href = `${window.location.origin}/403`;
  }

  // If User has not accepted Legal Agreements
  if (status === 451 && errors.find((err: JsonApiErrorObject) => err.code === 'E426')) {
    const legalError = errors.find((err: JsonApiErrorObject) => err.code === 'E426');
    store.commit('user/setPendingLegal', legalError.meta.pendingLegalAgreements);
    if (!isUserLoggedIn()) {
      store.commit('user/setIsUserLoggedInWithNoData', true);
    }
  }

  if (status === 422 && errors) {
    data.errors = deserializeErrors(errors);

    errors.forEach((err: JsonApiErrorObject) => {
      if (err.code && err.detail && ['E400', 'E405'].includes(err.code)) {
        dispatchToast({
          title: i18n.t('validation.toastTitle').toString(),
          message: err.detail,
        });
      }
    });
  }

  return Promise.reject(reason);
};
