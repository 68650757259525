// (c) 2021 Cofense Inc.
import Vue from 'vue';
import VueI18n, { LocaleMessages } from 'vue-i18n';
import { merge } from 'lodash-es';

Vue.use(VueI18n);

function loadLocaleMessages(localeContext?: __WebpackModuleApi.RequireContext): LocaleMessages {
  const localesShared = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i);

  const messages: LocaleMessages = {};
  localesShared.keys().forEach((key) => {
    const matched = /([A-Za-z0-9-_]+)\./i.exec(key);
    if (matched && matched.length > 1) {
      const locale = matched[1];
      const customLocaleMessages = localeContext && localeContext(key);
      messages[locale] = merge(localesShared(key), (customLocaleMessages || {}));
    }
  });
  return messages;
}

export function initI18n(localeContext?: __WebpackModuleApi.RequireContext): VueI18n {
  const messages = loadLocaleMessages(localeContext);

  return new VueI18n({
    locale: process.env.VUE_APP_I18N_LOCALE || 'en',
    fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
    messages,
    silentFallbackWarn: true,
  });
}
