<!-- (c) 2021 Cofense Inc. -->
<template>
  <component :is="inline ? 'koi-grid-row' : 'div'">
    <koi-form-choice
      v-for="option in options"
      :id="option.id"
      :key="option.id"
      v-model="localValue"
      :type="type"
      :disabled="disabled"
      :name="name"
      :inline="inline"
      :intent="intent"
      :value="option.value"
      :is-invalid="!!errors.length"
    >
      {{ option.label }}
    </koi-form-choice>
  </component>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import { Option } from '@/interfaces/Option';

  type ChoiceValue = string | string[] | boolean;

  export default Vue.component('cfp-form-choice-input', {
    name: 'cfp-form-choice-input',

    props: {
      options: {
        type: Array as PropType<Option[]>,
        required: true,
      },

      name: {
        type: String,
        required: true,
      },

      value: {
        type: [Boolean, Array, String] as PropType<ChoiceValue>,
        default: '',
      },

      type: {
        type: String as PropType<string>,
        required: true,
      },

      disabled: Boolean,

      errors: {
        type: Array as PropType<string[]>,
        required: true,
      },

      intent: {
        type: String as PropType<string>,
        default: 'neutral',
      },

      inline: Boolean,
    }, // props

    computed: {
      localValue: {
        get(): ChoiceValue {
          return this.value;
        },
        set(value: ChoiceValue): void {
          this.$emit('input', value);
        },
      },
    }, // computed
  });
</script>
