<!-- (c) 2024 Cofense Inc. -->
<template>
  <div :style="{ display: 'flex', flexDirection: 'column', alignItems: 'stretch' }">
    <div
      v-for="(item, index) in localValue"
      :key="item.id"
      v-space.bottom.md
    >
      <div :key="item.id" :style="{ border: '1px solid #ccc', borderRadius: '4px' }">
        <koi-grid-row v-space.all.xs align-v="middle" gutter="sm">
          <koi-grid-column>
            <koi-button
              :icon="item.isActive ? 'caret-down' : 'caret-right'"
              intent="tertiary"
              @click.native="item.isActive = !item.isActive"
            >
              {{ item.isActive ? 'Collapse' : 'Expand' }}
            </koi-button>
          </koi-grid-column>
          <koi-grid-column grow>
            <slot name="heading" :item="{ index, item }" />
          </koi-grid-column>
          <koi-grid-column>
            <koi-button
              icon="trash-alt"
              intent="danger"
              size="sm"
              @click.native="() => removeItem(item.id)"
            >
              {{ $t('generic.delete') }}
            </koi-button>
          </koi-grid-column>
          <koi-divider v-space.bottom.md />
        </koi-grid-row>
        <koi-grid-row v-if="item.isActive" v-space.all.sm gutter="sm">
          <koi-grid-column v-bind="{ ...$attrs }" grow>
            <slot :item="{ item, index }" />
          </koi-grid-column>
        </koi-grid-row>
      </div>
    </div>
    <koi-button
      :style="{ alignSelf: 'flex-end' }"
      intent="secondary"
      @click.native="addItem"
    >
      {{ buttonLabel }}
    </koi-button>
  </div>
</template>

<script lang="ts">
  import { Nullable } from '@cofense-ui/utils';
  import { nanoid } from 'nanoid';
  import Vue, { PropType } from 'vue';

  interface ConfigObject {
    language: string;
    emailText: string;
    emailSubtext: Nullable<string>;
    emailButtonLabel: string;
    scenarioText: string;
    scenarioSubtext: Nullable<string>;
    scenarioButtonLabel: string;
  }

  export default Vue.component('cfp-form-repeater-input', {
    name: 'cfp-form-repeater-input',

    props: {
      defaultConfig: {
        type: Object as PropType<ConfigObject>,
        required: true,
      },
      value: {
        type: Array as PropType<ConfigObject[]>,
        default: () => ([]),
      },
      buttonLabel: {
        type: String as PropType<string>,
        required: true,
      },
    },

    data: () => ({
      localValue: [] as (ConfigObject & { id: string, isActive: boolean })[],
    }),

    watch: {
      value: {
        deep: true,
        immediate: true,
        handler(newVal, oldVal) {
          if (newVal && (newVal.length !== oldVal?.length)) {
            this.localValue = newVal
              .map((item: ConfigObject) => ({ ...item, isActive: false, id: nanoid() }));
          }
        },
      },
    },

    methods: {
      addItem() {
        this.$emit('input', [...this.value, { ...this.defaultConfig }]);
      },

      removeItem(itemId: string) {
        this.$emit(
          'input',
          this.localValue
            .filter(({ id }) => itemId !== id)
            /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
            .map(({ id, isActive, ...rest }) => ({ ...rest })),
        );
      },
    },
  });
</script>
