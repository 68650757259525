// (c) 2021 Cofense Inc.
/* eslint-disable import/no-extraneous-dependencies */
import { IconDefinition, IconName } from '@fortawesome/fontawesome-svg-core';

export const prefix = 'fas';
export const iconName = 'product-reporter' as IconName;
export const width = 350;
export const height = 350;
export const ligatures = [] as string[];
export const unicode = 'e902';
export const svgPathData = 'm 188.41366,302.83675 a 11.108555,11.108555 0 0 1 -8.25203,3.17375 11.790936,11.790936 0 0 1 -8.41066,-3.17375 11.457647,11.457647 0 0 1 0,-15.8694 12.743105,12.743105 0 0 1 15.8693,0 10.648273,10.648273 0 0 1 3.17393,8.09311 10.283381,10.283381 0 0 1 -3.17393,7.93471 m 2.69777,-104.10273 -2.38024,73.47498 h -16.34556 l -2.38044,-73.47498 h 21.1061 z m -12.37806,-34.11927 -1.90436,-0.0154 c -48.05234,0 -87.598725,39.54655 -87.598725,87.59876 0,48.05242 39.546385,87.59889 87.598725,87.59889 48.05248,0 87.59875,-39.54647 87.59875,-87.59889 v -0.14246 C 264.07945,204.733 225.42106,165.29783 177.93998,164.77404 M 57.731406,136.98152 a 19.762809,19.762809 0 0 0 0,27.37854 18.567029,18.567029 0 0 0 26.749019,0 129.05649,129.05649 0 0 1 185.040795,0 18.567029,18.567029 0 0 0 26.74899,0 19.762809,19.762809 0 0 0 0,-27.37854 166.37941,166.37941 0 0 0 -238.538961,0 z M 348.19513,83.798097 a 239.05825,239.05825 0 0 0 -342.388322,0 19.762809,19.762809 0 0 0 0,27.378433 18.567029,18.567029 0 0 0 26.749043,0 A 201.65659,201.65659 0 0 1 177.0009,50.188693 c 54.3794,0 106.50858,22.01292 144.44506,60.987837 3.4775,3.68199 8.30818,5.79033 13.37471,5.8218 a 18.881676,18.881676 0 0 0 13.37446,-5.8218 19.762809,19.762809 0 0 0 0,-27.378433 z';

const definition = {
  prefix,
  iconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData,
  ],
} as IconDefinition;

export const faProductReporter = definition;
