// (c) 2021 Cofense Inc.
import { NavLink } from '@/interfaces/NavLink';
import { i18n } from '@/i18n';

const mapNavLinks = (nav: NavLink[]): NavLink[] => (
  nav.reduce((accumulator: NavLink[], currentValue: NavLink) => {
    accumulator.push({
      icon: currentValue.icon,
      label: i18n.t(currentValue.label).toString(),
      to: currentValue.to,
      links: currentValue.links ? mapNavLinks(currentValue.links) : [],
    });
    return accumulator;
  }, [])
);

export { mapNavLinks };
