<!-- (c) 2024 Cofense Inc. -->
<template>
  <validation-provider
    v-slot="{ errors, validate }"
    :name="name"
    :rules="rules.join('|')"
    slim
  >
    <koi-form-element :tag="fieldset ? 'fieldset' : 'div'">
      <koi-grid-row v-invisible="hideLabel" v-space.bottom.xxs align-v="bottom">
        <koi-grid-column>
          <koi-form-label
            :required="rules.includes('required') || $attrs.required"
            :for="fieldset ? null : id"
            :tag="fieldset ? 'span' : 'label'"
          >
            {{ formLabel }}
          </koi-form-label>
        </koi-grid-column>
        <koi-grid-column v-if="tooltip" v-space.left.xs>
          <info-tooltip :content="tooltip" size="button-sm" />
        </koi-grid-column>
        <koi-grid-column v-if="hasCharacterCount" :style="{ marginLeft: 'auto', marginRight: 0 }">
          <koi-form-character-limit :count="count" :limit="characterLimit" />
        </koi-grid-column>
      </koi-grid-row>
      <koi-form-control :prefix-icon="prefixIcon" :inline="inline">
        <koi-skeleton v-if="loading">
          <koi-skeleton-item color="light" height="36px" />
        </koi-skeleton>
        <slot
          v-else
          :errors="errors"
          v-bind="{ ...$attrs, validate }"
        />
      </koi-form-control>
      <koi-form-error v-if="errors && errors.length" v-space.top.xxs :messages="errors" />
    </koi-form-element>
  </validation-provider>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import { InfoTooltip } from '@/components/shared/info-tooltip';

  export default Vue.component('cfp-form-input-wrapper', {
    name: 'cfp-form-input-wrapper',

    components: {
      'info-tooltip': InfoTooltip,
    }, // components

    props: {
      id: {
        type: String as PropType<string>,
        required: true,
      },

      name: {
        type: String as PropType<string>,
        required: true,
      },

      hasCharacterCount: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      count: {
        type: Number as PropType<number>,
        default: 0,
      },

      rules: {
        type: Array as PropType<string[]>,
        default: () => ([]),
      },

      hideLabel: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      formLabel: {
        type: String as PropType<string>,
        required: true,
      },

      tooltip: {
        type: String as PropType<string>,
        default: '',
      },

      fieldset: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      inline: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      loading: {
        type: Boolean as PropType<boolean>,
        default: false,
      },

      prefixIcon: {
        type: String as PropType<string>,
        default: null,
      },
    }, // props

    computed: {
      characterLimit(): number {
        return +(this.rules
          .find(rule => rule.startsWith('max'))?.split(':')?.[1] || 0);
      },
    },
  });
</script>
