// (c) 2022 Cofense Inc.
import { NavigationGuard } from 'vue-router';
import { browserStorage } from '@/utils/browser-storage';
import { store } from '@/store';

let skipAuthCheck = false;
export const hasCurrentUser = (): boolean => store.getters['user/isUserLoggedIn'];
export const hasCurrentUserWithNoData = (): boolean => store.state.user.isUserLoggedInWithNoData;

export const fetchCurrentUser = async (): Promise<boolean> => {
  if (skipAuthCheck) {
    skipAuthCheck = false;
    return false;
  }
  try {
    await store.dispatch('user/fetchUser');
    return true;
  } catch {
    return false;
  }
};

const handleUserNeeded: NavigationGuard = async (to, from, next) => {
  if (hasCurrentUser()) {
    browserStorage.set('entry-url', null);
    next();
  } else if (await fetchCurrentUser()) {
    const nextUrl = browserStorage.get('entry-url');
    if (nextUrl) {
      window.location.href = `${window.location.origin}${nextUrl}`;
      browserStorage.set('entry-url', null);
    } else {
      next(to.fullPath);
    }
  } else {
    browserStorage.set('entry-url', to.fullPath);
    skipAuthCheck = true;
    next('/login');
  }
};

export const loginGuard: NavigationGuard = async (to, from, next) => {
  if (hasCurrentUser() || await fetchCurrentUser()) {
    next('/');
  } else {
    next();
  }
};

export const notFoundGuard: NavigationGuard = async (to, from, next) => {
  if (hasCurrentUser() || await fetchCurrentUser()) {
    next();
  } else {
    next('/');
  }
};

export const expireAuth: NavigationGuard = async (to, from, next): Promise<void> => {
  if (hasCurrentUser() || hasCurrentUserWithNoData()) {
    await store.dispatch('user/logout');
  }
  next('/login');
};

export const verifyAuth: NavigationGuard = async (to, from, next): Promise<void> => {
  const { isProtectedRoute } = to.meta;
  if (isProtectedRoute) {
    handleUserNeeded(to, from, next);
  } else {
    next();
  }
};
