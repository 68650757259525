// (c) 2021 Cofense Inc.
/* eslint-disable import/no-extraneous-dependencies */
import { IconDefinition, IconName } from '@fortawesome/fontawesome-svg-core';

export const prefix = 'far';
export const iconName = 'product-placeholder';
export const width = 512;
export const height = 512;
export const ligatures = [] as string[];
export const unicode = 'e903';
export const svgPathData = `
  M256,256
  m 0, -247
  a 247,247, 0, 1, 0, 1, 0
  Z
  m 1, 6
  a 241, 241, 0, 1, 1, -1, 0
  Z
  M244,12
  a12,12 0 1,0 24,0
  a12,12 0 1,0 -24,0
`;

const definition = {
  prefix: 'far',
  iconName: 'product-placeholder' as IconName,
  icon: [
    width,
    height,
    ligatures,
    unicode,
    svgPathData,
  ],
} as IconDefinition;

export const faProductPlaceholder = definition;
