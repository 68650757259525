<!-- (c) 2024 Cofense Inc. -->
<template>
  <transition name="koi_a-fade" mode="out-in">
    <cfp-app-spinner v-if="isFetching" :product="productName" />
    <koi-app v-else>
      <template v-if="isAuthenticated" #logotype>
        <cfp-product-org-switcher
          v-slot="{ currentPath }"
          :current="productName"
        >
          <koi-logotype
            v-space.left.sm
            size="sm"
            title="Cofense Platform"
            :to="currentPath"
          >
            <component :is="logo" />
          </koi-logotype>
        </cfp-product-org-switcher>
      </template>

      <template v-if="isAuthenticated" #profile>
        <div v-space.left.sm>
          <koi-profile
            data-qa-cfp="profile-info"
            :initials="initials"
            :links="profileLinks"
            :name="name"
          />
        </div>
      </template>

      <template v-if="isAuthenticated" #header>
        <koi-text
          v-space.left.xs
          size="sm"
          data-qa="current-org-name"
        >
          {{ currentOrg.name }}
        </koi-text>
        <cfp-toc-dropdown
          v-if="isToc && currentOrg"
          @legal-reset="openLegalResetConfirmationModal($event)"
        />
      </template>

      <template v-if="!isLegalConfirmationModalOpen && isAuthenticated" #sidebar>
        <koi-sidebar-navigation :links="sidebarLinks" :level="1" />
      </template>

      <template v-if="!isLegalConfirmationModalOpen" #view>
        <router-view :key="$route.path" />
      </template>

      <template v-if="!isLegalConfirmationModalOpen && isAuthenticated" #footer>
        <koi-footer :links="footerLinks">
          <koi-logotype
            slot="logotype"
            v-space.right.md
            size="sm"
            title="Cofense"
            to="https://cofense.com/"
          >
            <cofense-logotype />
          </koi-logotype>
        </koi-footer>
      </template>

      <template #modals>
        <portal-target name="modal" multiple />
        <portal-target name="app-modal" multiple />
        <discard-changes-modal />
        <legal-confirmation-modal
          :is-open="isLegalConfirmationModalOpen"
          data-qa-cfp="legal-confirmation-modal"
          :title="$t('modals.legal.title')"
          intent="info"
          @on-cancel="closeModalAndLogOut"
        >
          <template #content>
            <i18n v-space.bottom.md path="modals.legal.platform" tag="koi-text">
              <template #link>
                <koi-link to="https://cofense.com/legal/mssa/">{{ $t('modals.legal.platformLink') }}</koi-link>
              </template>
            </i18n>
            <koi-form-choice
              id="accept-legal-agreement-checkbox"
              v-model="isLegalAgreementChecked"
              type="checkbox"
              name="accept-legal-agreement-checkbox"
              data-qa="accept-legal-agreement-checkbox"
              :value="isLegalAgreementChecked"
            >
              {{ $t('modals.legal.checkboxLabel') }}
            </koi-form-choice>
          </template>
          <template #control-group-left>
            <koi-button
              intent="tertiary"
              data-qa="cancel-button"
              @click.native="closeModalAndLogOut"
            >
              {{ $t('modals.legal.disagree') }}
            </koi-button>
          </template>
          <template #control-group-right>
            <koi-button
              intent="primary"
              data-qa="confirm-button"
              :disabled="!isLegalAgreementChecked"
              @click.native="acceptLegalAgreement()"
            >
              {{ $t('modals.legal.agree') }}
            </koi-button>
          </template>
        </legal-confirmation-modal>
        <session-timeout-modal>
          <template #title>{{ $t('sessionTimeoutModal.title') }} </template>
          <template #content="{ secondsRemaining }">
            <koi-text>{{ $tc('sessionTimeoutModal.message', secondsRemaining) }}</koi-text>
          </template>
          <template #control>
            {{ $t('sessionTimeoutModal.button') }}
          </template>
        </session-timeout-modal>
        <leave-app-modal
          :cancel-text="$t('generic.cancel')"
          :continue-text="$t('generic.continue')"
          portal-name="app-modal"
          :title="$t('modals.exitSite.title')"
        >
          <template #content="{ url }">
            <i18n path="modals.exitSite.description" tag="koi-text">
              <template #url>
                <strong>{{ url }}</strong>
              </template>
            </i18n>
          </template>
        </leave-app-modal>
        <legal-confirmation-modal
          v-model="isResetLegalConfirmationModalOpen"
          data-qa-cfp="reset-legal-confirmation-modal"
          intent="error"
          :title="resetLegalText.title"
          :message="resetLegalText.message"
          :button-text="$t('generic.reset')"
          @on-confirm="resetLegal"
        />
      </template>
    </koi-app>
  </transition>
</template>

<script lang="ts">
  import Vue, { VueConstructor } from 'vue';
  import { documentTitleMixin } from '@/mixins/document-title';
  import CofenseLogotype from '@koi/static-assets/logotypes/cofense-email-security-color.svg';
  import { footerLinks, profileLinks } from '@/constants/links';
  import { sidebarLinks } from '@/constants/platform-links';
  import { NavLink } from '@/interfaces/NavLink';
  import { mapNavLinks } from '@/utils/nav-links';
  import { productSvgs, ProductSvgs } from '@/constants/user/products';
  import {
    mapState,
    mapGetters,
    mapActions,
    mapMutations,
  } from 'vuex';
  import { AppSpinner } from '@/components/app-spinner';
  import { LegalAgreement } from '@/interfaces/user/LegalAgreement';
  import { Products, LegalProducts } from '@/enums/Products';
  import { ProductOrgSwitcher } from '@/components/product-org-switcher';
  import { TocDropdown } from '@/components/toc-dropdown';
  import { nanoid } from 'nanoid';
  import { api } from '@/services/api';
  import { userApi } from '@/constants/user/api';

  type ProductKeys = keyof typeof Products;

  export default Vue.extend({
    name: 'app',

    components: {
      'cofense-logotype': CofenseLogotype,
      'cfp-app-spinner': AppSpinner,
      'cfp-product-org-switcher': ProductOrgSwitcher,
      'cfp-toc-dropdown': TocDropdown,
    },

    mixins: [documentTitleMixin],

    data: () => ({
      footerLinks: mapNavLinks(footerLinks as NavLink[]),
      profileLinks: mapNavLinks(profileLinks as NavLink[]),
      svgs: { ...productSvgs } as ProductSvgs,
      isLegalAgreementChecked: false,
      legalName: LegalProducts.platform,
      isResetLegalConfirmationModalOpen: false,
      selectedOptionsValue: '',
    }), // data

    computed: {
      ...mapState('user', {
        isFetching: 'isFetching',
      }),

      ...mapGetters('user', {
        name: 'fullName',
        initials: 'initials',
        currentOrg: 'currentOrg',
        isAuthenticated: 'isUserLoggedIn',
        isToc: 'isToc',
        pendingLegal: 'pendingLegal',
      }),

      isLegalConfirmationModalOpen(): boolean {
        return (
          !!this.pendingLegal
          && this.pendingLegal.some((legal: LegalAgreement) => legal.productId === this.legalName)
        );
      },

      resetLegalText(): Record<string, string> {
        if (this.selectedOptionsValue === 'reset-toc') {
          return {
            title: this.$t('modals.resetLegal.org.title').toString(),
            message: this.$t('modals.resetLegal.org.message', { name: this.currentOrg.name }).toString(),
          };
        }
        if (this.selectedOptionsValue === 'reset-all-toc') {
          return {
            title: this.$t('modals.resetLegal.all.title').toString(),
            message: this.$t('modals.resetLegal.all.message').toString(),
          };
        }
        return {};
      },

      logo(): VueConstructor<Vue> {
        const logotype = this.svgs[this.productName]?.logotype || { ...CofenseLogotype };
        return { ...logotype } as VueConstructor<Vue>;
      },

      productName(): ProductKeys {
        const productName = this.$route?.matched
          .find(record => record.name)?.name?.split('.')[0] as ProductKeys || 'platform';
        return Products[productName] ? productName : 'platform';
      },

      sidebarLinks(): NavLink[] {
        return mapNavLinks(sidebarLinks as NavLink[]);
      },
    }, // computed

    watch: {
      productName: {
        handler(currentProduct) {
          this.$store.commit('user/setProduct', currentProduct);
          if (this.isAuthenticated) {
            this.$store.dispatch('user/fetchUser');
          }
        },
        immediate: true,
      },
    }, // watch
    methods: {
      openLegalResetConfirmationModal(value: string) {
        this.isResetLegalConfirmationModalOpen = true;
        this.selectedOptionsValue = value;
      },
      async resetLegal(): Promise<void> {
        if (this.selectedOptionsValue === 'reset-toc') {
          await api.update(userApi.resetOrgLegalTerms(this.currentOrg.id));
        } else if (this.selectedOptionsValue === 'reset-all-toc') {
          await api.update(userApi.resetAllLegalTerms);
        }
        this.$koi.toast({
          id: nanoid(),
          title: this.$t('generic.success').toString(),
          message: this.$t('toasts.updateShort', { name: this.$t('generic.legal') }).toString(),
          intent: 'success',
          duration: 7500,
        });
      },
      closeModalAndLogOut() {
        this.setPendingLegal([]);
        /* eslint-disable-next-line @typescript-eslint/no-empty-function */
        this.$router.push('/logout').catch(() => {});
      },
      async acceptLegalAgreement() {
        const legalAgreement = this.pendingLegal.find(
          (legal: LegalAgreement) => legal.productId === this.legalName,
        );
        await this.updateLegal(legalAgreement.termsAndConditionsCaptureId);
        this.$router.go(0);
      },
      ...mapActions('user', {
        updateLegal: 'updateLegal',
      }),
      ...mapMutations('user', {
        setPendingLegal: 'setPendingLegal',
      }),
    },
  });
</script>
