// (c) 2021 Cofense Inc.
import { immutable } from '@cofense-ui/utils';
import { NavLink } from '@/interfaces/NavLink';

export const sidebarLinks = immutable<NavLink[]>([
  {
    icon: 'tachometer-alt',
    label: 'views.platform.dashboard',
    to: '/dashboard',
  },
]);
