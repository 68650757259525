// (c) 2022 Cofense Inc.
import { extend } from 'vee-validate';
import {
  email,
  max,
  max_value as maxValue,
  required,
  numeric,
} from 'vee-validate/dist/rules';
import { i18n } from '@/i18n';
import {
  hasLowercase,
  hasUppercase,
  hasNumber,
  hasSpecialChar,
} from './custom';

extend('email', {
  ...email,
  message: i18n.t('validation.errors.email').toString(),
});

extend('numeric', {
  ...numeric,
  message: i18n.t('validation.errors.numeric').toString(),
});

extend('required', {
  ...required,
  message: i18n.t('validation.errors.required').toString(),
});

extend('required_choice', {
  validate: value => value === true,
  message: i18n.t('validation.errors.required').toString(),
});

extend('required_choice_if', {
  params: ['target'],
  validate: (value, { target }: Record<string, any>) => !target || (value === true && target),
  message: i18n.t('validation.errors.required').toString(),
});

extend('max', {
  ...max,
  message: (_, { length }) => i18n
    .t('validation.errors.maxLength', {
      length: length.toLocaleString(),
    })
    .toString(),
});

extend('maxValue', {
  ...maxValue,
  message: i18n.t('validation.errors.maxValue').toString(),
});

extend('passwordLength', {
  validate: value => value && value.length > 11,
  message: i18n.t('validation.errors.password.length').toString(),
});
extend('passwordLowercase', {
  validate: value => value && hasLowercase(value),
  message: i18n.t('validation.errors.password.lowercase').toString(),
});
extend('passwordNumber', {
  validate: value => value && hasNumber(value),
  message: i18n.t('validation.errors.password.number').toString(),
});
extend('passwordSpecial', {
  validate: value => value && hasSpecialChar(value),
  message: i18n.t('validation.errors.password.special').toString(),
});

extend('passwordUppercase', {
  validate: value => value && hasUppercase(value),
  message: i18n.t('validation.errors.password.uppercase').toString(),
});

extend('passwordMatch', {
  params: ['target'],
  validate(value, { target }: Record<string, any>) {
    return value === target;
  },
  message: i18n.t('validation.errors.passwordMatch').toString(),
});
