// (c) 2022 Cofense Inc.
import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import { parse as parseQuery, stringify } from '@/utils/query-params';
import { verifyAuth } from './guards';
import { routes as platformRoutes } from './routes';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  ...platformRoutes,
];

const router = new VueRouter({
  linkActiveClass: 'is-current-route',
  mode: 'history',
  parseQuery,
  stringifyQuery: query => (Object.keys(query).length ? `?${decodeURI(stringify(query, false))}` : ''),
  base: '/',
  routes,
});

router.beforeEach(verifyAuth);

export { router };
