// (c) 2023 Cofense Inc.
// Vue
import Vue from 'vue';

// Plugins
import 'focus-visible';
import Portal from 'portal-vue';

// Koi
import Koi from '@koi/vue-library';
import '@koi/styles/dist/main.css';

// 3rd party components
import { ValidationProvider, ValidationObserver } from 'vee-validate';

// Global components
import { ApiMutate, ApiQuery } from '@/components/api-components';
import { NotFound } from '@/components/not-found';

// Application styles
import '@/styles/main.scss';

// Router and store
import { router } from '@/router';
import { store } from '@/store';

// Internationalization
import { i18n } from '@/i18n';

// Default component
import { App } from '@/components/app';

// Plugins
import { SessionTimeout, SessionTimeoutModal, LeaveAppModal } from '@cofense-ui/koi-addons';

import { DiscardChanges } from '@/plugins/discard-changes';
import { ConfirmationModal } from '@/components/confirmation-modal';

// Icons
import '@/icons';

// Validation rules
import '@/validation';

import { instance as client } from '@/services/api/client';
import { api } from '@/services/api';
import { userApi } from './constants/user/api';

/* eslint-disable-next-line no-console */
console.log('Version', process.env.VUE_APP_COMMIT_HASH);

// Register plugins
Vue.use(Koi);
Vue.use(Portal);
Vue.use(DiscardChanges);
Vue.use(SessionTimeout, {
  header: 'x-cofense-platform-authentication-ttl',
  formatTime: (epochMilliseconds: string) => +epochMilliseconds,
  axiosInstance: client,
  refreshEndpoint: () => api.find(userApi.currentUser),
  router,
});

// Register 3rd party components
Vue.component('validation-provider', ValidationProvider);
Vue.component('validation-observer', ValidationObserver);
Vue.component('session-timeout-modal', SessionTimeoutModal);
Vue.component('leave-app-modal', LeaveAppModal);
Vue.component('legal-confirmation-modal', ConfirmationModal);

// Register global components
Vue.component('not-found', NotFound);
Vue.component('api-mutate', ApiMutate);
Vue.component('api-query', ApiQuery);

Vue.config.productionTip = false;

// Mount application
export const instance = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app');
