<!-- (c) 2022 Cofense Inc. -->
<template functional>
  <koi-grid-row :align-h="props.align">
    <koi-grid-column>
      <koi-button
        v-t="'generic.cancel'"
        intent="tertiary"
        @click.native="parent.$router.push(props.cancelRoute)"
      />
    </koi-grid-column>
    <koi-grid-column>
      <koi-button
        v-t="props.submitButtonText"
        tag="button"
        data-qa="submit-button"
        :disabled="props.disabled"
        type="submit"
      />
    </koi-grid-column>
  </koi-grid-row>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';

  interface Props {
    align: string;
    cancelRoute: string;
    disabled: boolean;
    submitButtonText: string;
  }

  export default Vue.component<Props>('cfp-form-buttons', {
    functional: true,

    props: {
      align: {
        type: String as PropType<string>,
        default: 'between',
      },
      cancelRoute: {
        type: String as PropType<string>,
        required: true,
      },
      disabled: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
      submitButtonText: {
        type: String as PropType<string>,
        default: 'generic.save',
      },
    },
  });
</script>
