// (c) 2021 Cofense Inc.
import { Nullable } from '@cofense-ui/utils';

export const PREFIX = 'cofense-platform-ui';

export type Entry = Nullable<Record<string, string | string[]>>;

export const browserStorage = {
  get(key: string): Entry {
    try {
      const storedValue = sessionStorage.getItem(`${PREFIX}-${key}`);

      if (storedValue !== null) {
        return JSON.parse(storedValue);
      }

      return null;
    } catch (error) {
      return null;
      // browser may not allow access to Storage
    }
  },
  set(key: string, value: string | string[] | null): void {
    const actualKey = `${PREFIX}-${key}`;

    try {
      if (value !== null) {
        sessionStorage.setItem(actualKey, JSON.stringify(value));
      } else {
        sessionStorage.removeItem(actualKey);
      }
    } catch (error) {
      // browser may not allow access to Storage
    }
  },
};
