// (c) 2021 Cofense Inc.
import {
  Module,
  GetterTree,
  MutationTree,
} from 'vuex';
import { RootState } from '@/store/interfaces';
import { Nullable } from '@cofense-ui/utils';

export interface OktaState extends RootState {
  domain: Nullable<string>;
  clientId: Nullable<string>;
  redirectUrl: Nullable<string>;
}

export const okta: Module<OktaState, RootState> = {
  namespaced: true,
  state: {
    domain: null,
    clientId: null,
    redirectUrl: null,
  },
  getters: {
    domain(state) {
      return state.domain;
    },
    clientId(state) {
      return state.clientId;
    },
    redirectUrl(state) {
      return state.redirectUrl;
    },
  } as GetterTree<OktaState, RootState>, // getters
  mutations: {
    setEnvironment(state, { domain, clientId, redirectUrl }) {
      state.domain = domain;
      state.clientId = clientId;
      state.redirectUrl = redirectUrl;
    },
  } as MutationTree<OktaState>, // mutations
};
