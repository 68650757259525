// (c) 2021 Cofense Inc.
import Jsona from 'jsona';
import {
  TJsonApiBody,
  TJsonaModel,
  TJsonaNormalizedIncludeNamesTree,
} from 'jsona/lib/JsonaTypes';
import { JsonApiErrorObject, DeserializedErrors } from '@/interfaces/Error';

const formatter = new Jsona();

export type JsonaPayload = {
  payload: TJsonaModel | Array<TJsonaModel>;
  relationships: TJsonaNormalizedIncludeNamesTree;
}

export const deserialize = (
  payload: TJsonApiBody,
): TJsonaModel | Array<TJsonaModel> => formatter.deserialize(payload);

export const serialize = ({
  payload,
  relationships,
}: JsonaPayload): TJsonApiBody => formatter.serialize({
  stuff: payload,
  includeNames: relationships,
});

export const deserializeErrors = (
  errors: JsonApiErrorObject[],
): DeserializedErrors => (
  errors.reduce((acc, err) => {
    const isParameter = !!err.source?.parameter;
    const key = isParameter
      ? err.source?.parameter
      : (err.source?.pointer?.startsWith('/data/attributes')
      && err
        .source
        .pointer
        .substring(err.source.pointer.lastIndexOf('/') + 1)) || err.code;
    const { detail } = err;

    if (key && detail) {
      acc[key] = acc[key]
        ? [...acc[key], detail]
        : [detail];
    }
    return acc;
  }, {} as DeserializedErrors)
);
