// (c) 2022 Cofense Inc.
import qs from 'qs';
import { QueryParams } from '@/interfaces/QueryParams';

export const parse = (query: string): Record<string, any> => qs.parse(query);

export const stringify = (query: QueryParams, encode = true): string => qs.stringify(query, {
  arrayFormat: 'comma',
  encode,
});
