<!-- (c) 2022 Cofense Inc. -->
<template>
  <koi-grid-row align-v="middle" gutter="sm" :style="{ height: '500px', }">
    <koi-grid-column v-size.3of5>
      <div v-space.horizontal.xl>
        <koi-badge v-space.bottom.xs intent="error" v-text="localBadge" />
        <div>
          <koi-headline v-space.bottom.xs size="xxs" tag="h1">
            {{ localTitle }}
          </koi-headline>
          <slot>
            <koi-text v-space.bottom.md v-t="'contactSupport'" size="sm" color="light" />
          </slot>
          <koi-button
            intent="primary"
            tag="router-link"
            :to="to"
            size="sm"
            v-text="localButton"
          />
        </div>
      </div>
    </koi-grid-column>
    <koi-grid-column v-size.2of5>
      <div class="c-header-status">
        <div class="c-header-status__number">4</div>
        <slot name="logomark">
          <koi-logomark size="xl">
            <pr-red-logo />
          </koi-logomark>
        </slot>
        <div class="c-header-status__number">4</div>
      </div>
    </koi-grid-column>
  </koi-grid-row>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import { RawLocation } from 'vue-router';
  import reporterRed from '@koi/static-assets/logomarks/reporter-red.svg';

  export default Vue.extend({
    name: 'not-found',

    components: {
      'pr-red-logo': reporterRed,
    },

    props: {
      to: {
        default: '/',
        type: [String, Object] as PropType<RawLocation | string>,
      },

      title: {
        type: String as PropType<string>,
        default: undefined,
      },
      badge: {
        type: String as PropType<string>,
        default: undefined,
      },
      button: {
        type: String as PropType<string>,
        default: undefined,
      },

    },
    data() {
      return {
        localButton: this.button ? this.button : this.$t('goHome'),
        localBadge: this.badge ? this.badge : this.$t('notFound'),
        localTitle: this.title ? this.title : this.$t('notFound'),
      };
    },
  });
</script>

<i18n src="./translations.json" />
