// (c) 2024 Cofense Inc.
import Vue, { PropType } from 'vue';
import { Nullable } from '@cofense-ui/utils';
import { AxiosError, AxiosResponse } from 'axios';

export const ApiComponentMixin = Vue.extend({
  props: {
    endpoint: {
      required: true,
      type: String as PropType<string>,
    },
  }, // props

  data: () => ({
    response: null as Nullable<Record<string, unknown>>,
    reason: null as Nullable<Record<string, unknown>>,
    requesting: false,
  }), // data

  methods: {
    setResponse(response: AxiosResponse): void {
      const { data: { data, meta } } = response;
      this.response = data || {};
      this.$emit('on-success', { data, meta });
    },

    setReason(reason: AxiosError): void {
      const data = reason.response?.data as Record<string, unknown>;
      this.reason = data || {};
      this.$emit('on-error', data?.errors);
    },

    reset(): void {
      this.response = null;
      this.reason = null;
    },
  }, // methods
});
