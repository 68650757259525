<!-- (c) 2021 Cofense Inc. -->
<template>
  <koi-form-datepicker :options="options">
    <koi-form-input
      :value="value"
      :is-invalid="!!errors.length"
      @input="$emit('input', $event)"
    />
  </koi-form-datepicker>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import { Options } from 'flatpickr/dist/types/options';

  export default Vue.component('cfp-form-datepicker', {
    name: 'cfp-form-datepicker',

    props: {
      id: {
        type: String as PropType<string>,
        required: true,
      },

      disabled: Boolean,

      name: {
        type: String as PropType<string>,
        default: 'date',
      },

      value: {
        type: String as PropType<string>,
        default: '',
      },

      errors: {
        type: Array as PropType<string[]>,
        required: true,
      },

      calendarOptions: {
        type: Object as PropType<Options>,
        default: () => ({}),
      },
    },

    computed: {
      options(): Options {
        return {
          altInput: false,
          ...this.calendarOptions,
        };
      },
    },
  });
</script>
