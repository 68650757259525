// (c) 2024 Cofense Inc.
import { AxiosHeaders, AxiosRequestHeaders, InternalAxiosRequestConfig } from 'axios';
import { serialize } from '@/services/api/formatters/json-api';
import { ApiContentTypes } from '@/enums/ApiContentTypes';
import { stringify } from '@/utils/query-params';

export const onRequest = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const reqHeaders: AxiosRequestHeaders = new AxiosHeaders({
    accept: request.headers?.accept || 'application/vnd.api+json, application/json',
    'content-type': request.headers?.['content-type'] || 'application/vnd.api+json',
    'X-Requested-With': 'XMLHttpRequest',
  });
  /* eslint-disable no-param-reassign */
  request.headers = reqHeaders;
  request.paramsSerializer = {
    serialize: params => stringify(params, true),
  };
  request.transformRequest = [(data, headers) => {
    if (data && (headers?.['content-type'] as string).includes(ApiContentTypes.jsonapi)) {
      try {
        delete data.payload?.links;
        return JSON.stringify(serialize(data));
      } catch (_) {
        return JSON.stringify(data);
      }
    } else if (data && headers?.['content-type'] === 'json') {
      headers['content-type'] = ApiContentTypes.jsonapi;
      return JSON.stringify(data);
    }
    return JSON.stringify(data);
  }];

  /* eslint-enable no-param-reassign */
  return request;
};
