// (c) 2021 Cofense Inc.
import { immutable } from '@cofense-ui/utils';
import { NavLink } from '@/interfaces/NavLink';

export const footerLinks = immutable<NavLink[]>([
  {
    label: 'links.aboutCofense',
    to: 'https://cofense.com/company/overview/',
  },
  {
    label: 'links.news',
    to: 'https://cofense.com/news/',
  },
  {
    label: 'links.events',
    to: 'https://cofense.com/events/',
  },
  {
    label: 'links.contactUs',
    to: 'https://cofense.com/contact/',
  },
  {
    label: 'links.blog',
    to: 'https://cofense.com/blog/',
  },
  {
    label: 'links.acceptableUsePolicy',
    to: 'https://cofense.com/legal/mssa#acceptable-use-addendum',
  },
  {
    label: 'links.agreement',
    to: 'https://cofense.com/legal/mssa',
  },
  {
    label: 'links.followUs',
    to: 'http://twitter.com/cofense',
  },
]);

export const loginFooterLinks = immutable<NavLink[]>([
  {
    label: 'links.acceptableUsePolicy',
    to: 'https://cofense.com/legal/mssa#acceptable-use-addendum',
  },
  {
    label: 'links.agreement',
    to: 'https://cofense.com/legal/mssa',
  },
]);

export const profileLinks = immutable<NavLink[]>([
  {
    icon: 'user',
    label: 'views.platform.account',
    to: '/account',
  },
  {
    icon: 'sign-out',
    label: 'views.platform.logout',
    to: '/logout',
  },
]);
