<!-- (c) 2021 Cofense Inc. -->
<template>
  <div class="cfp_c-app-spinner">
    <cfp-product-icon
      :product="product"
      class="cfp_c-app-spinner__app-logo"
      has-ring
      animate
    />
    <svg :viewBox="`0 0 ${width} ${width}`">
      <g
        v-for="({ startingPosition: { x, y } }, index) in circles"
        :key="`orb-${index}`"
        class="cfp_c-app-spinner__group"
      >
        <circle :cx="x" :cy="y" r="12" class="cfp_c-app-spinner__orb" />
      </g>
      <g>
        <circle
          v-for="({ radius }, index) in circles"
          :key="`circle-${index}`"
          :cx="halfWidth"
          :cy="halfWidth"
          :r="radius"
          :stroke-dasharray="index === circles.length - 1 ? (2 * radius * pi) / 256 : 0"
          class="cfp_c-app-spinner__circle"
        />
      </g>
    </svg>
  </div>
</template>

<script lang="ts">
  import Vue from 'vue';
  import { ProductIcon } from '@/components/product-icon';

  interface Circle {
    angle: number;
    radius: number;
    startingPosition: { x: number; y: number };
  }

  export default Vue.component('cfp-app-spinner', {
    components: {
      'cfp-product-icon': ProductIcon,
    },

    props: {
      product: {
        type: String,
        required: true,
      },
    },

    data() {
      return {
        width: 750,
        scale: 0.75,
        angles: [90, 215],
      };
    },

    computed: {
      range(): number[] {
        return [(this.halfWidth * this.scale) + 40, this.halfWidth - 10];
      },
      circles(): Circle[] {
        const { range, startingPosition, angles } = this;
        return angles.map((angle, index) => {
          const radius = range[index];
          return {
            angle,
            radius,
            startingPosition: startingPosition(radius, angle),
          };
        });
      },
      halfWidth(): number {
        return this.width / 2;
      },
      pi(): number {
        return Math.PI;
      },
    },

    methods: {
      startingPosition(radius: number, angle: number): { x: number; y: number } {
        const radians = (this.pi / 180) * (angle + 90);
        return {
          x: this.halfWidth + (radius * Math.sin(radians)),
          y: this.halfWidth + (radius * Math.cos(radians)),
        };
      },
    },

  });
</script>
