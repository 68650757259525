// (c) 2024 Cofense Inc.
import { Immutable, immutable } from '@cofense-ui/utils';
import { Services } from '@/apps/reporter/enums/Services';

export type ReporterEndpoints = 'buttons' | 'phishmeCheckinUrls' | 'logo';
type ReporterApi = (orgId: string, buttonId?: string) => Immutable<{
  [key in ReporterEndpoints]: string
}>

export const reporterApi: ReporterApi = (orgId, buttonId) => immutable({
  buttons: `${Services.reporter}/${orgId}/buttons${buttonId ? `/${buttonId}` : ''}`,
  logo: `${Services.reporter}/${orgId}/buttons${buttonId ? `/${buttonId}` : ''}/logos`,
  phishmeCheckinUrls: `${Services.reporter}/phishme-checkin-urls`,
});

export type AssetEndpoints = 'assets'

type AssetsApi = (buttonId: string) => Immutable<{
  [key in AssetEndpoints]: string;
}>

export const assetsApi: AssetsApi = buttonId => immutable({
  assets: `${Services.assets}/${buttonId}/logo/icon128.png`,
});

