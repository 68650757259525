// (c) 2021 Cofense Inc.
import Vue, { PropType, VNode } from 'vue';
import { ProductSvgs, productSvgs } from '@/constants/user/products';
import { Products } from '@/enums/Products';

type ProductKeys = keyof typeof Products;

export const Product = Vue.extend({
  name: 'cfp-product-item',

  props: {
    isActive: {
      type: Boolean as PropType<boolean>,
      required: true,
    },
    path: {
      type: String as PropType<string>,
      required: true,
    },
    name: {
      type: String as PropType<ProductKeys>,
      required: true,
    },
    label: {
      type: String as PropType<string>,
      required: true,
    },
  }, // props

  data: () => ({
    icons: { ...productSvgs } as ProductSvgs,
  }),

  computed: {
    content(): JSX.Element[] {
      const { icon } = this.icons[this.name];
      return [
        <koi-icon
          v-space_bottom_xxs
          name={icon}
          variant="solid"
          size="headline-sm"
          data-qa-cfp="icon"
        />,
        <koi-text data-qa-cfp="text" size="sm">{ this.label }</koi-text>,
      ];
    },

    classes(): string[] {
      return [
        'cfp_c-product-switcher__tile',
        ...(this.isActive ? ['is-current'] : []),
      ];
    },

    bindings(): Record<string, any> {
      return {
        class: this.classes,
        on: this.isActive ? { click: this.clickHandler } : {},
      };
    },
  }, // computed

  methods: {
    clickHandler(): void {
      this.$emit('click');
    },
  },

  render(): VNode {
    const { bindings } = this;
    return !this.isActive
      ? (<a data-qa-cfp="link" { ...bindings } href={this.path}>{this.content}</a>)
      : (<span { ...bindings }>{this.content}</span>);
  },
});
