// (c) 2024 Cofense Inc.
/* eslint-disable import/no-extraneous-dependencies */
import {
  dom,
  library,
} from '@fortawesome/fontawesome-svg-core';

import {
  // Required by Koi
  faCheck as fasCheck,
  faExclamation as fasExclamation,
  faExclamationTriangle as fasExclamationTriangle,
  faInfo as fasInfo,

  // Application specific
  faArrowDown as fasArrowDown,
  faArrowUp as fasArrowUp,
  faCircle as fasCircle,
  faHome as fasHome,
  faGlobe as fasGlobe,
  faGripHorizontal as fasGripHorizontal,
  faHomeAlt as fasHomeAlt,
  faMinus as fasMinus,
} from '@fortawesome/pro-solid-svg-icons';

import {
  // Required by Koi
  faAngleDown as farAngleDown,
  faAngleUp as farAngleUp,
  faAngleDoubleRight as farAngleDoubleRight,
  faAngleDoubleLeft as farAngleDoubleLeft,
  faCaretDown as farCaretDown,
  faCaretUp as farCaretUp,
  faChevronRight as farChevronRight,
  faChevronLeft as farChevronLeft,
  faChevronUp as farChevronUp,
  faChevronDown as farChevronDown,
  faCopy as farCopy,
  faFileCsv as farFileCsv,
  faSort as farSort,
  faTimes as farTimes,
  faTimesCircle as farTimesCircle,

  // Application specific
  faCaretRight as farCaretRight,
  faEdit as farEdit,
  faEllipsisV as farEllipsisV,
  faHistory as farHistory,
  faHome as farHome,
  faKey as farKey,
  faPlus as farPlus,
  faShare as farShare,
  faTrashAlt as farTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';

import {
  // Required by Koi
  faQuoteLeft as fadQuoteLeft,
  faSpinnerThird as fadSpinnerThird,

  // Application specific
  faBan as fadBan,
  faBlog as fadBlog,
  faBuilding as fadBuilding,
  faCalendarAlt as fadCalendarAlt,
  faChartArea as fadChartArea,
  faChartBar as fadChartBar,
  faCheckCircle as fadCheckCircle,
  faCheckDouble as fadCheckDouble,
  faDownload as fadDownload,
  faEdit as fadEdit,
  faEnvelope as fadEnvelope,
  faExclamationCircle as fadExclamationCircle,
  faEye as fadEye,
  faHome as fadHome,
  faIdCard as fadIdCard,
  faKey as fadKey,
  faMinusCircle as fadMinusCircle,
  faPencil as fadPencil,
  faPlusCircle as fadPlusCircle,
  faQuestion as fadQuestion,
  faQuestionCircle as fadQuestionCircle,
  faRedo as fadRedo,
  faSignOut as fadSignOut,
  faSpinner as fadSpinner,
  faTachometerAlt as fadTachometerAlt,
  faTimesCircle as fadTimesCircle,
  faToggleOff as fadToggleOff,
  faToggleOn as fadToggleOn,
  faTrashAlt as fadTrashAlt,
  faUser as fadUser,
  faUsers as fadUsers,
  faUserPlus as fadUserPlus,
} from '@fortawesome/pro-duotone-svg-icons';

// Custom Icons
import { faAdminApp as fadAdminApp } from './admin-app';
import { faProductPlaceholder as farProductPlaceholder } from './product-placeholder-regular';
import { faProductReporter as fasProductReporter } from './product-reporter-solid';
import { faProductValidator as fasProductValidator } from './product-validator-solid';
import { faTh as fasTh } from './th-solid';

// Register and observe FA icons
library.add(
  // Required By Koi - solid
  fasCheck,
  fasExclamation,
  fasExclamationTriangle,
  fasInfo,

  // Application specifc - solid
  fasArrowDown,
  fasArrowUp,
  fasCircle,
  fasGlobe,
  fasGripHorizontal,
  fasHomeAlt,
  fasHome,
  fasMinus,
  fasProductReporter,
  fasProductValidator,
  fasTh,

  // Required By Koi - regular
  farAngleDown,
  farAngleUp,
  farAngleDoubleRight,
  farAngleDoubleLeft,
  farCaretDown,
  farCaretUp,
  farChevronRight,
  farChevronLeft,
  farChevronUp,
  farChevronDown,
  farCopy,
  farSort,
  farTimes,

  // Required By Koi - duotone
  fadQuoteLeft,
  fadSpinnerThird,

  // Application specific - regular
  farCaretRight,
  farEdit,
  farEllipsisV,
  farFileCsv,
  farHistory,
  farHome,
  farKey,
  farPlus,
  farProductPlaceholder,
  farShare,
  farTrashAlt,
  farTimesCircle,

  // Application specific - duotone
  fadBan,
  fadAdminApp,
  fadBlog,
  fadBuilding,
  fadCalendarAlt,
  fadChartArea,
  fadChartBar,
  fadCheckCircle,
  fadCheckDouble,
  fadDownload,
  fadEdit,
  fadEnvelope,
  fadExclamationCircle,
  fadEye,
  fadHome,
  fadIdCard,
  fadKey,
  fadMinusCircle,
  fadPencil,
  fadPlusCircle,
  fadRedo,
  fadQuestion,
  fadQuestionCircle,
  fadSpinner,
  fadTachometerAlt,
  fadToggleOff,
  fadToggleOn,
  fadSignOut,
  fadTimesCircle,
  fadTrashAlt,
  fadUser,
  fadUserPlus,
  fadUsers,
);

dom.watch();
