// (c) 2021 Cofense Inc.
import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { user } from './modules/user';
import { okta } from './modules/okta';
import { RootState } from './interfaces';

Vue.use(Vuex);

const options: StoreOptions<RootState> = {
  state: {},
  modules: {
    okta,
    user,
  },
};

export const store = new Vuex.Store<RootState>(options);
