<!-- (c) 2021 Cofense Inc. -->
<template>
  <portal to="modal">
    <transition name="koi_a-fade">
      <koi-modal
        v-if="isOpen"
        :id="id"
        data-qa="discard-changes-modal"
        intent="error"
        :is-dismissable="false"
      >
        <template slot="title">
          {{ $t('modals.discardChanges.title') }}
        </template>
        <template slot="content">
          <koi-text v-t="'modals.discardChanges.content'" size="sm" />
        </template>
        <template slot="control-group-left">
          <koi-button
            intent="tertiary"
            data-qa="cancel"
            @click.native="onClearRoute"
          >
            {{ $t('generic.cancel') }}
          </koi-button>
        </template>
        <template slot="control-group-right">
          <koi-button
            intent="danger"
            data-qa="continue"
            @click.native="onContinue"
          >
            {{ $t('generic.continue') }}
          </koi-button>
        </template>
      </koi-modal>
    </transition>
  </portal>
</template>

<script lang="ts">
  import Vue from 'vue';
  import { nanoid } from 'nanoid';

  export default Vue.extend({
    name: 'pm-discard-changes-modal',

    data: () => ({
      id: nanoid(),
    }),

    computed: {
      isOpen: {
        get(): boolean {
          const {
            attemptedRoute,
            isAttemptingRouteChange,
          } = this.$discardChanges;
          return attemptedRoute !== null || isAttemptingRouteChange;
        },
        set(isOpen: boolean) {
          if (!isOpen) {
            this.$discardChanges.attemptedRoute = null;
            this.$discardChanges.isAttemptingRouteChange = false;
            window.onbeforeunload = null;
          } else {
            window.onbeforeunload = () => true;
          }
        },
      },
    },

    methods: {
      onContinue() {
        this.$discardChanges.isViewDirty = false;
        const { attemptedRoute } = this.$discardChanges;
        if (attemptedRoute) {
          this.$router.push({ path: attemptedRoute.path });
        }

        this.onClearRoute();
      },

      onClearRoute() {
        this.isOpen = false;
      },
    },
  });
</script>
