// (c) 2022 Cofense Inc.
import { VNode, CreateElement, PropType } from 'vue';
import axios, {
  AxiosRequestConfig,
  CancelTokenSource,
} from 'axios';
import { QueryParams } from '@/interfaces/QueryParams';
import { api } from '@/services/api';
import { ApiComponentMixin } from './ApiComponentMixin';

const getCancelTokenSource = () => axios.CancelToken.source();

export const ApiQuery = ApiComponentMixin.extend({
  name: 'api-query',

  props: {
    immediate: {
      default: true,
      type: Boolean,
    },

    params: {
      default: () => ({}),
      type: Object as PropType<QueryParams>,
    },
  }, // props

  data: () => ({
    source: getCancelTokenSource() as CancelTokenSource,
  }), // data

  computed: {
    config(): AxiosRequestConfig {
      return {
        cancelToken: this.source.token,
        params: this.params,
      };
    },
  }, // computed

  watch: {
    params: {
      handler(): void {
        this.fetch();
      },
      deep: true,
    },
    requesting(isFetching: boolean): void {
      this.$emit('on-fetch', isFetching);
    },
  }, // watch

  beforeDestroy(): void {
    if (this.requesting) {
      this.source.cancel();
    }
  }, // beforeDestroy

  created(): void {
    if (this.immediate) {
      this.fetch();
    }
  },

  methods: {
    async fetch(): Promise<void> {
      if (this.requesting) {
        return;
      }

      this.requesting = true;
      this.reset();

      try {
        const response = await api.find(this.endpoint, this.config);
        this.setResponse(response);
      } catch (reason: any) {
        if (!axios.isCancel(reason)) {
          this.setReason(reason);
        }
      } finally {
        this.requesting = false;
      }
    },
  }, // methods

  render(h: CreateElement): VNode {
    const {
      response,
      reason,
      requesting,
      fetch,
    } = this;

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return h('div', this.$scopedSlots.default!({
      response,
      reason,
      fetching: requesting,
      fetch,
    }));
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }, // render
});
