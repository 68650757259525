// (c) 2022 Cofense Inc.
import { RouteConfig } from 'vue-router';
import { QueryParams } from '@/interfaces/QueryParams';
import { expireAuth, loginGuard, notFoundGuard } from './guards';

export const routes: RouteConfig[] = [
  {
    path: '/',
    redirect: '/dashboard',
  },

  {
    path: '/ui',
    redirect: '/dashboard',
  },

  {
    path: '/platform',
    redirect: '/dashboard',
  },

  {
    path: '/logout',
    name: 'logout',
    beforeEnter: expireAuth,
  },

  {
    path: '/login',
    name: 'login',
    component: () => (
      import(
        /* webpackChunkName: "chunk.view.platform.login" */ '@/views/login'
      ).then(({ Login }) => Login)
    ),
    beforeEnter: loginGuard,
    meta: {
      title: 'views.platform.login',
    },
  },

  {
    path: '/forgot-password',
    name: 'platform.forgotPassword',
    component: () => (
      import(
        /* webpackChunkName: "chunk.view.platform.forgotPassword" */ '@/views/forgot-password'
      ).then(({ ForgotPassword }) => ForgotPassword)
    ),
    beforeEnter: loginGuard,
    meta: {
      title: 'views.platform.forgotPassword',
    },
  },

  {
    path: '/dashboard',
    name: 'platform.dashboard',
    component: () => (
      import(
        /* webpackChunkName: "chunk.view.platform.dashboard" */ '@/views/dashboard'
      ).then(({ Dashboard }) => Dashboard)
    ),
    meta: {
      title: 'views.platform.dashboard',
      isProtectedRoute: true,
    },
  },

  {
    path: '/account',
    name: 'platform.account',
    component: () => (
      import(
        /* webpackChunkName: "chunk.view.platform.account" */ '@/views/account'
      ).then(({ Account }) => Account)
    ),
    meta: {
      title: 'views.platform.account',
      isProtectedRoute: true,
    },
  },

  {
    path: '*',
    name: 'platform.not-found',
    beforeEnter: notFoundGuard,
    component: () => (
      import(
        /* webpackChunkName: "chunk.view.platform.not-found" */ '@/views/not-found'
      ).then(({ NotFound }) => NotFound)
    ),
    meta: {
      title: 'views.platform.notFound',
    },
  },

  {
    path: '/org-404',
    name: 'platform.org-not-found',
    component: () => (
      import(
        /* webpackChunkName: "chunk.view.platform.not-found" */ '@/views/org-not-found'
      ).then(({ OrgNotFound }) => OrgNotFound)
    ),
    meta: {
      title: 'views.platform.notFound',
      isProtectedRoute: true,
    },
  },

  {
    path: '/403',
    name: 'platform.forbidden',
    component: () => (
      import(
        /* webpackChunkName: "chunk.view.platform.forbidden" */ '@/views/forbidden'
      ).then(({ Forbidden }) => Forbidden)
    ),
    props: (): QueryParams => ({ code: ['4', '0', '3'] }),
    meta: {
      title: 'views.platform.forbidden',
      isProtectedRoute: true,
    },
  },

  {
    path: '/500',
    name: 'platform.server-error',
    component: () => (
      import(
        /* webpackChunkName: "chunk.view.platform.server-error" */ '@/views/server-error'
      ).then(({ ServerError }) => ServerError)
    ),
    props: (): QueryParams => ({ code: ['5', '0', '0'] }),
    meta: {
      title: 'views.platform.forbidden',
      isProtectedRoute: true,
    },
  },
];
