// (c) 2021 Cofense Inc.
import Vue from 'vue';
import { Route } from 'vue-router';

const pageTitleSuffix = '- Cofense Platform';

const documentTitleMixin = Vue.extend({
  watch: {
    $route(to) {
      this.setDocumentTitle(to);
    },
    // eslint-disable-next-line object-shorthand
    '$i18n.locale'() {
      this.setDocumentTitle();
    },
  },

  methods: {
    setDocumentTitle(targetRoute?: Route) {
      const to = targetRoute || this.$router.currentRoute;

      if (to.meta?.title) {
        document.title = `${this.$i18n.t(to.meta.title)} ${pageTitleSuffix}`;
      }
    },
  },
});

export { documentTitleMixin };
