// (c) 2021 Cofense Inc.
import { immutable } from '@cofense-ui/utils';
import { nanoid } from 'nanoid';

interface SupportLink {
  id: string;
  label: string;
  icon: string;
  href: string;
  variant: string;
}

export const supportLinks = immutable<SupportLink[]>([
  {
    id: nanoid(),
    label: 'links.blog',
    href: 'https://cofense.com/blog/',
    icon: 'blog',
    variant: 'duotone',
  },
  {
    id: nanoid(),
    label: 'links.support',
    href: 'https://support.cofense.com/',
    icon: 'globe',
    variant: 'solid',
  },
  {
    id: nanoid(),
    label: 'links.resources',
    href: 'https://cofense.com/resources/',
    icon: 'user',
    variant: 'duotone',
  },
]);
