// (c) 2021 Cofense Inc.
import Vue, { PropType } from 'vue';
import {
  icon,
  layer,
  Icon,
  IconParams,
  Layer,
} from '@fortawesome/fontawesome-svg-core';
import { faProductPlaceholder } from '@/icons/product-placeholder-regular';
import { Products } from '@/enums/Products';
import { ProductSvgs, productSvgs } from '@/constants/user/products';

type ProductKeys = keyof typeof Products;

export const ProductIcon = Vue.extend({
  name: 'cfp-product-icon',

  props: {
    classNames: {
      type: Array as PropType<string[]>,
      default: () => ([]),
    },
    product: {
      type: String as PropType<ProductKeys>,
      required: true,
    },
    animate: Boolean,
    hasRing: Boolean,
  },

  data: () => ({
    svgs: { ...productSvgs } as ProductSvgs,
  }),

  computed: {
    logomark(): { icon: (params?: IconParams) => Icon; rotate: number; } {
      return this.svgs[this.product as ProductKeys].logomark;
    },
    ring(): Icon {
      const { animate, logomark: { rotate } } = this;
      return icon(faProductPlaceholder, {
        transform: { rotate },
        classes: ['cfp_c-product-icon__background']
          .concat(animate ? ['fa-spin'] : []),
      });
    },
    renderedIcon(): Layer {
      const { hasRing, ring } = this;
      const { icon: activeIcon } = this.logomark;

      return layer((push) => {
        push(activeIcon({
          classes: ['cfp_c-product-icon__foreground']
            .concat(this.hasRing ? ['is-reduced'] : []),
        }));
        if (hasRing) { push(ring); }
      });
    },
  },

  render() {
    const productIcon = this.renderedIcon.html[0];
    const { classNames } = this;
    return (
      <span
        {
          ...{
            class: ['cfp_c-product-icon']
              .concat(classNames),
            domProps: {
              innerHTML: productIcon,
            },
          }
        }
      />
    );
  },

});
