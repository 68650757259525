// (c) 2024 Cofense Inc.
import axios, {
  AxiosInstance,
  AxiosResponse,
  AxiosError,
  InternalAxiosRequestConfig,
} from 'axios';
import { onResponse, onResponseFailure, onRequest } from './interceptors';

const instance: AxiosInstance = axios.create({
  baseURL: '/',
});

instance.interceptors.request.use(
  (req: InternalAxiosRequestConfig) => onRequest(req),
);

instance.interceptors.response.use(
  (res: AxiosResponse) => onResponse(res),
  (err: AxiosError) => onResponseFailure(err),
);

export { instance };
