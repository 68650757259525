// (c) 2022 Cofense Inc.
export enum Products {
  platform = 'Home',
  reporter = 'Reporter',
  validator = 'Validator',
  admin = 'Admin'
}

export enum LegalProducts {
  platform = 'portal',
  reporter = 'reporter',
  validator = 'validator'
}
