// (c) 2023 Cofense Inc.
import {
  Module,
  GetterTree,
  MutationTree,
  ActionTree,
} from 'vuex';
import { api } from '@/services/api';
import { userApi } from '@/constants/user/api';
import { ModuleState, RootState } from '@/store/interfaces';
import { Nullable } from '@cofense-ui/utils';
import { User } from '@/interfaces/user/User';
import { Organization } from '@/interfaces/user/Organization';
import { Product } from '@/interfaces/user/Product';
import { LegalAgreement } from '@/interfaces/user/LegalAgreement';
import { Products } from '@/enums/Products';
import { Roles } from '@/enums/Roles';
import { ResponseType } from '@/enums/user/ResponseTypes';

export interface UserProfileState extends ModuleState {
  user: Nullable<any>;
  product: Nullable<Products>;
  pendingLegalAgreements: Nullable<LegalAgreement[]>;
  isUserLoggedInWithNoData: boolean;
}

export const user: Module<UserProfileState, RootState> = {
  namespaced: true,
  state: {
    isFetching: false,
    user: null,
    isUserLoggedInWithNoData: false,
    product: null,
    pendingLegalAgreements: null,
  },
  getters: {
    fullName(state): string {
      return `${state.user.firstName} ${state.user.lastName}`;
    },
    email(state): string {
      return state.user?.email;
    },
    initials(_, { fullName }): string {
      return fullName.match(/\b\w/g)?.join('') || '';
    },
    userId(state): string {
      return state.user?.id;
    },
    isUserLoggedIn(state): boolean {
      return !!state.user;
    },
    isSso(state): boolean {
      return state.user?.isSso;
    },
    isToc(state): boolean {
      return state.user?.isTocUser;
    },
    isAdmin(_, { isToc, globalProduct }): boolean {
      return isToc || globalProduct?.roleNames?.includes(Roles.orgAdmin);
    },
    organizations(state): Organization[] {
      return state?.user?.organizations || [];
    },
    currentOrg(state, { organizations }): Organization {
      return state?.user?.userPreferences?.currentOrganization || organizations[0];
    },
    currentOrgId(_, { currentOrg }): string {
      return currentOrg?.id;
    },
    myOrganization(state): Organization {
      return state?.user?.myOrganization;
    },
    globalProduct(_, { currentOrg }): Product {
      return (currentOrg?.products || [])
        .find((product: Product) => product.id === 'GLOBAL');
    },
    pendingLegal(state): Nullable<LegalAgreement[]> {
      return state?.pendingLegalAgreements;
    },
    product(state): Nullable<string> {
      return state?.product;
    },
    products(_, { currentOrg, isAdmin, globalProduct }): Product[] {
      return [
        {
          id: 'platform',
          name: 'Platform',
          roleNames: [],
          type: 'product' as ResponseType.product,
        }, ...(isAdmin ? [{
          id: 'admin',
          name: 'Admin',
          roleNames: ['Admin'].concat(globalProduct?.roleNames || []),
          type: 'product' as ResponseType.product,
        }] : [])].concat(
        (currentOrg?.products || [])
          .filter((product: Product) => product.id !== 'GLOBAL')
          .map((product: Product) => ({ ...product, id: product.id.toLowerCase() })),
      );
    },
    currentProduct(state, { products }): Product {
      return products.find(({ id }: { id: string }) => state.product === id);
    },
    roles(_, { currentProduct }): string[] {
      return currentProduct?.roleNames || [];
    },
  } as GetterTree<UserProfileState, RootState>, // getters
  mutations: {
    currentUser(state, currentUser) {
      state.user = currentUser;
    },
    setIsUserLoggedInWithNoData(state, isUserLoggedInWithNoData) {
      state.isUserLoggedInWithNoData = isUserLoggedInWithNoData;
    },
    isFetching(state, isFetching) {
      state.isFetching = isFetching;
    },
    setProduct(state, product) {
      state.product = product;
    },
    setPendingLegal(state, pendingLegal) {
      state.pendingLegalAgreements = pendingLegal;
    },
  } as MutationTree<UserProfileState>, // mutations

  actions: {
    async fetchUser({ commit }) {
      commit('isFetching', true);
      try {
        const { data: { data } }: { data: { data: User } } = await api.find(userApi.currentUser, {
          params: { include: ['organizations', 'organizations.products', 'userPreferences', 'myOrganization'].join(',') },
        });
        commit('currentUser', data);
      } finally {
        commit('isFetching', false);
      }
    },
    async updateLegal({ commit }, legalId) {
      commit('isFetching', true);
      try {
        const { data: { data } }:
          { data: { data: LegalAgreement[] } } = await api.update(userApi.legal(legalId));
        commit('setPendingLegal', data);
      } finally {
        commit('isFetching', false);
      }
    },
    async logout({ commit }) {
      commit('isFetching', true);
      try {
        await api.create(userApi.logout, { payload: { type: 'logout' } });
        commit('currentUser', null);
        commit('setIsUserLoggedInWithNoData', false);
        commit('setPendingLegal', []);
      } finally {
        commit('isFetching', false);
      }
    },
  } as ActionTree<UserProfileState, RootState>,
};
