// (c) 2022 Cofense Inc.
export enum Intent {
  error = 'error',
  info = 'info',
  neutral = 'neutral',
  success = 'success',
  warning = 'warning'
}

export enum ButtonIntent {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  subdued = 'subdued',
  danger = 'danger'
}
