// (c) 2021 Cofense Inc.
import { VNode, CreateElement, PropType } from 'vue';
import { api } from '@/services/api';
import { AxiosError } from 'axios';
import { ApiComponentMixin } from './ApiComponentMixin';

type ApiMethods = 'create' | 'destroy' | 'update';

export const ApiMutate = ApiComponentMixin.extend({
  name: 'api-mutate',
  props: {
    method: {
      required: true,
      type: String as PropType<ApiMethods>,
      validator: (value: string) => ['create', 'destroy', 'update'].includes(value),
    },
  }, // props

  methods: {
    async mutate(payload?: Record<string, any>): Promise<void> {
      if (this.requesting) {
        return;
      }

      this.requesting = true;
      this.reset();

      try {
        const response = await api[this.method as ApiMethods](this.endpoint, payload);
        this.setResponse(response);
      } catch (reason) {
        this.setReason(reason as AxiosError<any>);
      } finally {
        this.requesting = false;
      }
    },
  }, // methods

  render(h: CreateElement): VNode {
    const {
      response,
      reason,
      requesting,
      mutate,
    } = this;

    /* eslint-disable @typescript-eslint/no-non-null-assertion */
    return h('div', this.$scopedSlots.default!({
      response,
      reason,
      mutating: requesting,
      mutate,
    }));
    /* eslint-enable @typescript-eslint/no-non-null-assertion */
  }, // render
});
