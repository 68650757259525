// (c) 2021 Cofense Inc.
import _Vue from 'vue';
import { Route } from 'vue-router';
import { router } from '@/router';
import DiscardChangesModal from './DiscardChangesModal.vue';

export interface Data {
  attemptedRoute: Route | null;
  isAttemptingRouteChange: boolean;
  isViewDirty: boolean;
}

const DiscardChanges = {
  install(Vue: typeof _Vue): any {
    const data = new Vue({
      data(): Data {
        return {
          isViewDirty: false,
          attemptedRoute: null,
          isAttemptingRouteChange: false,
        };
      },
    });

    Object.defineProperty(Vue.prototype, '$discardChanges', {
      get() { return data; },
    });

    Vue.component('discard-changes-modal', DiscardChangesModal);
    router.beforeEach((to, from, next) => {
      const isSameRoute = to.name === from.name
        || to.path === from.path;

      if (data.isViewDirty && !isSameRoute) {
        data.attemptedRoute = to;
        window.onbeforeunload = () => true;
      } else {
        next();
      }
    });
  },
};

export { DiscardChanges };
