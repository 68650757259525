// (c) 2022 Cofense Inc.

export const hasLowercase = (value: string): boolean => (
  /'[a-z]'/.test(value)
);

export const hasUppercase = (value: string): boolean => (
  /'[A-Z]'/.test(value)
);

export const hasNumber = (value: string): boolean => (
  /'\\d'/.test(value)
);

export const hasSpecialChar = (value: string): boolean => (
  /'[.,!@#$%^&*()_+=-]'/.test(value)
);
