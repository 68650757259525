// (c) 2023 Cofense Inc.
import { immutable, Nullable } from '@cofense-ui/utils';
import { Services } from '@/enums/Services';

export const userApi = immutable({
  changePassword: `${Services.user}/api/v1/current-user/change-password`,
  currentUser: `${Services.user}/api/v1/current-user`,
  preferences: `${Services.user}/api/v1/current-user/preferences`,
  organizations: `${Services.user}/api/v1/current-user/organizations`,
  resetAllLegalTerms: `${Services.user}/api/v1/legal/reset`,
  graphStatusReport: (orgId: string) => `${Services.user}/api/v1/admin/graphStatusReport/${orgId}`,
  triggerLegalError: (orgId: string) => `/portal/api/v1/portal/${orgId}`,
  divisions: (orgId: string, id?: Nullable<string>) => `${Services.user}/api/v1/organization/${orgId}/divisions${id ? `/${id}` : ''}`,
  resetOrgLegalTerms: (orgId: string) => `${Services.user}/api/v1/organization/${orgId}/legal/reset`,
  securityGroups: (orgId: string, id?: Nullable<string>) => `${Services.user}/api/v1/organization/${orgId}/securityGroups${id ? `/${id}` : ''}`,
  securityGroup: (orgId: string, id?: Nullable<string>) => `${Services.user}/api/v1/organization/${orgId}/securityGroup${id ? `/${id}` : ''}`,
  customer: (id?: Nullable<string>) => `${Services.user}/api/v1/admin/customer${id ? `/${id}` : ''}`,
  legal: (id?: Nullable<string>) => `${Services.user}/api/v1/legal${id ? `/${id}` : ''}`,
  customerLicense: (id: string) => `${Services.user}/api/v1/admin/customer/${id}/licenses`,
  forgotPassword: '/api/v1/authn/recovery/password',
  logout: '/api/logout',
});
