<!-- (c) 2024 Cofense Inc. -->
<template>
  <koi-grid-row gutter="sm">
    <koi-grid-column v-size.1of3>
      <koi-button
        :disabled="disabled"
        :prefix-icon="buttonIcon"
        :loading="loading"
        intent="secondary"
        @click.native="selectFile()"
      >
        {{ buttonText }}
      </koi-button>
    </koi-grid-column>
    <koi-grid-column v-if="buttonId || localImageUrl" v-size.1of3>
      <img
        :style="{
          width: '128px',
          height: '128px'
        }"
        :src="localImageUrl ? localImageUrl : imageUrl"
      >
    </koi-grid-column>
    <koi-grid-column v-size.1of3>
      <koi-form-element>
        <koi-form-label hidden for="file-upload-input">File Uploader</koi-form-label>
        <koi-form-control class="cfp-file-uploader" size="md">
          <input
            id="file-upload-input"
            ref="fileUploaderInput"
            data-qa="form-input-field"
            :accept="extensions.join(',')"
            name="file-upload-input"
            type="file"
            :style="{ display: 'none' }"
            :disabled="loading || disabled"
            @change="onFileChange"
          >
        </koi-form-control>
        <koi-form-error
          v-if="errors && errors.length"
          v-space.top.xs
          :messages="errors"
        />
      </koi-form-element>
    </koi-grid-column>
  </koi-grid-row>
</template>

<script lang="ts">
  import { Nullable } from '@cofense-ui/utils';
  import Vue, { PropType } from 'vue';
  import { ValidationResult } from 'vee-validate/dist/types/types';
  import { assetsApi, reporterApi } from '@/apps/reporter/constants/reporter-api';

  export default Vue.component('cfp-form-file-upload', {

    name: 'cfp-form-file-upload',

    model: {
      prop: 'value',
      event: 'input',
    },

    props: {
      name: {
        type: String as PropType<string>,
        required: true,
      },
      buttonText: {
        type: String as PropType<string>,
        required: true,
      },
      buttonId: {
        type: String as PropType<Nullable<string>>,
        default: null,
      },
      validate: {
        type: Function as PropType<() => Promise<ValidationResult>>,
        required: true,
      },
      errors: {
        type: Array as PropType<string[]>,
        required: true,
      },
      buttonIcon: {
        type: String as PropType<string>,
        default: '',
      },
      disabled: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
      loading: {
        type: Boolean as PropType<boolean>,
        default: false,
      },
      extensions: {
        type: Array as PropType<string[]>,
        default: () => [],
      },
      maxFileSizeInKilobytes: {
        type: Number as PropType<number>,
        default: 500,
      },
    },

    data: () => ({
      selectedFile: null as Nullable<File>,
      localImageUrl: null as Nullable<string>,
      hasValidationErrors: false as boolean,
    }),

    computed: {
      imageUrl(): Nullable<string> {
        return this.buttonId ? `${window.location.origin}${assetsApi(this.buttonId).assets}` : null;
      },
      validationRules(): string {
        return `ext:${this.extensions.join(',').replace(/\./g, '')}|size:${this.maxFileSizeInKilobytes}`;
      },
      endpoint(): Nullable<string> {
        return this.buttonId
          ? reporterApi(this.$store.getters['user/currentOrgId'], this.buttonId).logo
          : null;
      },
    },

    methods: {
      selectFile() {
        const fileInputElement = this.$refs.fileUploaderInput as HTMLInputElement;
        fileInputElement.click();
      },
      async onFileChange(event: Event) {
        const input = event.target as HTMLInputElement;
        this.selectedFile = input.files?.length ? input.files[0] : null;
        await this.validate();
        if (this.selectedFile) {
          this.localImageUrl = URL.createObjectURL(this.selectedFile);
          this.encodeImageToBase64(this.selectedFile).then((base64) => {
            this.$emit('input', {
              file: base64,
              filename: this.selectedFile?.name,
              contentType: this.selectedFile?.type,
            });
          });
        }
        this.$emit('input', this.selectedFile);
      },
      async encodeImageToBase64(file: File) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onloadend = () => {
            if (reader.result) {
              const base64String = (reader.result as string).split(',')[1];
              resolve(base64String);
            } else {
              reject(new Error('Failed to convert file to base64'));
            }
          };

          reader.onerror = (error) => {
            reject(error);
          };

          reader.readAsDataURL(file);
        });
      },
    },

  });
</script>
