<!-- (c) 2022 Cofense Inc. -->
<template>
  <div class="cfp_c-product-switcher">
    <koi-dropdown-card
      :max-width="300"
      :max-content-height="500"
      align="bottom-start"
    >
      <template #trigger="{ onToggle, ariaToggleProps, isOpen }">
        <koi-button
          v-bind="ariaToggleProps"
          size="sm"
          intent="tertiary"
          :class="{
            'is-active': isOpen,
            'cfp_c-product-switcher__toggle': true,
          }"
          @click.native="checkDirtyState(onToggle)"
        >
          <koi-icon
            variant="solid"
            name="th"
            size="title-md"
          />
          <span
            v-t="'switchProducts'"
            v-invisible
          />
        </koi-button>
      </template>

      <template #content="{ onClose }">
        <cfp-organization-switcher
          v-space.bottom.sm
        />
        <koi-grid-row wrap>
          <koi-grid-column
            v-for="product in products"
            :key="product.id"
            v-size.1of3
          >
            <cfp-product
              v-bind="product"
              :is-active="current === product.name"
              @click="onClose"
            />
          </koi-grid-column>
        </koi-grid-row>
        <koi-divider />
        <koi-grid-row>
          <koi-grid-column
            v-for="link in supportLinks"
            :key="link.id"
          >
            <a
              class="cfp_c-product-switcher__tile"
              data-qa-cfp="link"
              :href="link.href"
            >
              <koi-icon
                v-space.bottom.xxs
                :name="link.icon"
                :variant="link.variant"
                size="headline-sm"
                data-qa-cfp="icon"
              /><koi-text v-align.center v-t="link.label" size="sm" />
            </a>
          </koi-grid-column>
        </koi-grid-row>
      </template>
    </koi-dropdown-card>
    <slot v-bind="{ currentPath }" />
  </div>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import { Products } from '@/enums/Products';
  import { Product } from '@/interfaces/Product';
  import { OrganizationSwitcher } from '@/components/organization-switcher';
  import { Product as ProductComponent } from './Product';
  import { supportLinks } from './support-links-config';

  type ProductKeys = keyof typeof Products;

  interface ProductOption extends Omit<Product, 'type'> {
    id: string;
    path: string;
    name: ProductKeys;
  }

  export default Vue.extend({
    name: 'cfp-product-org-switcher',

    components: {
      'cfp-organization-switcher': OrganizationSwitcher,
      'cfp-product': ProductComponent,
    },

    props: {
      current: {
        required: true,
        type: String as PropType<Products>,
      },
    }, // props
    data: () => ({
      supportLinks,
    }),

    computed: {
      currentPath(): string {
        return this.products.find(({ name }) => this.current === name.toLowerCase())?.path || '/';
      },
      products(): ProductOption[] {
        return this.$store.getters['user/products']
          .filter(
            ({ name }: ProductOption) => this.$store.getters['user/currentOrg'].enabled
              || [Products.admin, Products.platform]
                .includes(Products[name.toLowerCase() as ProductKeys])
            ,
          )
          .map(
            ({
              name, id, roleNames,
            }: ProductOption) => ({
              path: `/${name.toLowerCase()}`,
              label: Products[name.toLowerCase() as ProductKeys],
              id,
              name: name.toLowerCase() as ProductKeys,
              roleNames,
            }),
          );
      },
    }, // computed
    methods: {
      checkDirtyState(onToggle: Function): void {
        if (this.$discardChanges.isViewDirty) {
          this.$discardChanges.isAttemptingRouteChange = true;
        } else {
          onToggle();
        }
      },
    },
  });
</script>

<i18n src="./translations.json" />
