// (c) 2022 Cofense Inc.
export enum Roles {
  licenseAdmin = 'License Admin',
  orgAdmin = 'Organization Admin',
  reporterAdmin = 'Reporter Admin',
  reporterStatsReader = 'Reporter Stats Reader',
  securityAdmin = 'Security Admin',
  validatorAdmin = 'Validator Admin',
  tcAdmin = 'TandC Admin',
}
