// (c) 2023 Cofense Inc.
import { Immutable, immutable } from '@cofense-ui/utils';
import { RawLocation } from 'vue-router';
import { userApi } from '@/constants/user/api';
import { nanoid } from 'nanoid';

export interface DropdownOptions {
  id: string;
  label: string;
  icon: string;
  to?: string | RawLocation;
  tag?: string;
}

export const orgOptionsConst = (
  orgId: string,
): Immutable<DropdownOptions[]> => immutable<DropdownOptions[]>([
  {
    id: nanoid(),
    label: 'createCustomer',
    icon: 'user-plus',
    to: '/admin/customer-org',
  },
  {
    id: nanoid(),
    label: 'legalReset',
    icon: 'redo',
    to: '/admin/legal-reset',
  },
  {
    id: nanoid(),
    label: 'graphReport',
    icon: 'download',
    tag: 'a',
    to: userApi.graphStatusReport(orgId),
  },
]);
