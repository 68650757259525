// (c) 2021 Cofense Inc.
import { AxiosResponse } from 'axios';
import { deserialize } from '@/services/api/formatters/json-api';
import { ApiContentTypes } from '@/enums/ApiContentTypes';

export const onResponse = (response: AxiosResponse): AxiosResponse => {
  const { data, headers } = response;

  let formattedData = null;
  if (headers['content-type']?.includes(ApiContentTypes.jsonapi)) {
    formattedData = {
      data: data ? deserialize(data) : {},
      meta: data?.meta ?? {},
    };
  }

  return {
    ...response,
    data: formattedData ?? data,
  };
};
