<!-- (c) 2022 Cofense Inc. -->
<template>
  <portal to="modal">
    <transition name="koi_a-fade">
      <koi-modal
        v-if="isOpen"
        id="confirmation-modal"
        :intent="intent"
        size="md"
        :on-dismiss="onCancel"
        data-qa="confirmation-modal"
      >
        <template #title>
          <slot name="title">
            {{ title }}
          </slot>
        </template>
        <template #content>
          <slot name="content">
            {{ message }}
          </slot>
        </template>
        <template #control-group-left>
          <slot name="control-group-left">
            <koi-button
              intent="tertiary"
              data-qa="cancel-button"
              @click.native="onCancel"
            >
              {{ $t('generic.cancel') }}
            </koi-button>
          </slot>
        </template>
        <template #control-group-right>
          <slot name="control-group-right">
            <koi-button
              :intent="buttonIntent"
              data-qa="confirm-button"
              @click.native="onConfirm"
            >
              {{ buttonText }}
            </koi-button>
          </slot>
        </template>
      </koi-modal>
    </transition>
  </portal>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';
  import { Intent, ButtonIntent } from '@/enums/Intent';
  import { i18n } from '@/i18n';

  export default Vue.extend({
    name: 'confirmation-modal',
    model: {
      prop: 'is-open',
      event: 'is-open',
    }, // model
    props: {
      isOpen: {
        required: true,
        type: Boolean as PropType<boolean>,
      },
      title: {
        default: i18n.t('modals.confirm.title').toString(),
        type: String as PropType<string>,
      },
      message: {
        default: i18n.t('modals.confirm.message').toString(),
        type: String as PropType<string>,
      },
      buttonText: {
        default: i18n.t('modals.confirm.buttonText').toString(),
        type: String as PropType<string>,
      },
      intent: {
        default: Intent.error,
        type: String as PropType<keyof typeof Intent>,
      },
    }, // props
    computed: {
      buttonIntent() {
        switch (this.intent) {
          case Intent.error:
            return ButtonIntent.danger;
          case Intent.info:
            return ButtonIntent.primary;
          default:
            return ButtonIntent.tertiary;
        }
      },
    }, // computed
    methods: {
      exitModal() {
        this.$emit('is-open', false);
      },
      onCancel() {
        this.$emit('on-cancel');
        this.exitModal();
      },
      onConfirm() {
        this.$emit('on-confirm');
        this.exitModal();
      },
    }, // methods
  });
</script>
