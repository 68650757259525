// (c) 2022 Cofense Inc.
import Vue, { VueConstructor } from 'vue';
import { Products } from '@/enums/Products';
import reporterLogotype from '@koi/static-assets/logotypes/reporter-color.svg';
import cofenseLogotype from '@koi/static-assets/logotypes/cofense-email-security-color.svg';
import validatorLogotype from '@koi/static-assets/logotypes/validator-color.svg';
import {
  icon,
  Icon,
  IconDefinition,
  IconParams,
} from '@fortawesome/fontawesome-svg-core';
import { immutable } from '@cofense-ui/utils';
import { faHomeAlt } from '@fortawesome/pro-solid-svg-icons';
import { faProductReporter } from '@/icons/product-reporter-solid';
import { faProductValidator } from '@/icons/product-validator-solid';
import { faAdminApp } from '@/icons/admin-app';

type ProductKeys = keyof typeof Products;
type Logos = {
  logomark: {
    icon: (params?: IconParams) => Icon;
    rotate: number;
  };
  logotype: VueConstructor<Vue>;
  icon: string;
}

export type ProductSvgs = {
  [key in ProductKeys]: Logos;
}

const generateIcon = (initialIcon: IconDefinition, params?: IconParams) => icon(
  initialIcon,
  { ...params, mask: { iconName: 'circle', prefix: 'fas' } },
);

export const productSvgs = immutable<ProductSvgs>({
  admin: {
    logotype: { ...cofenseLogotype } as VueConstructor<Vue>,
    logomark: {
      icon: params => generateIcon(
        faAdminApp,
        { ...params, transform: { size: 9, x: -0.25 } },
      ),
      rotate: 315,
    },
    icon: 'admin-app',
  },
  platform: {
    logotype: { ...cofenseLogotype } as VueConstructor<Vue>,
    logomark: {
      icon: params => generateIcon(
        faHomeAlt,
        { ...params, transform: { size: 9, y: 0 } },
      ),
      rotate: 45,
    },
    icon: 'home',
  },
  reporter: {
    logotype: { ...reporterLogotype } as VueConstructor<Vue>,
    logomark: {
      icon: params => generateIcon(
        faProductReporter,
        { ...params, transform: { y: 2.5 } },
      ),
      rotate: 0,
    },
    icon: 'product-reporter',
  },
  validator: {
    logotype: { ...validatorLogotype } as VueConstructor<Vue>,
    logomark: {
      rotate: 315,
      icon: params => generateIcon(
        faProductValidator,
        { ...params, transform: { size: 9 } },
      ),
    },
    icon: 'product-validator',
  },
});
