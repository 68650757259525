import { render, staticRenderFns } from "./FormRepeater.vue?vue&type=template&id=b0c464d4"
import script from "./FormRepeater.vue?vue&type=script&lang=ts"
export * from "./FormRepeater.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports