<!-- (c) 2023 Cofense Inc. -->
<template>
  <koi-dropdown-card
    :max-width="270"
    :max-content-height="220"
    :style="{ 'margin-left': 'auto' }"
  >
    <template #trigger="{ onToggle, isOpen, ariaToggleProps }">
      <koi-button
        :is-active="isOpen"
        intent="subdued"
        size="sm"
        data-qa="toc-button"
        v-bind="ariaToggleProps"
        suffix-icon="ellipsis-v"
        @click.native="onToggle"
      >
        {{ $t('title') }}
      </koi-button>
    </template>
    <template #content>
      <koi-title
        v-space.bottom.xs
        color="dark"
        size="sm"
        tag="h2"
      >
        {{ $t('orgTitle') }}
      </koi-title>
      <ul class="cfp_c-action-list">
        <li
          v-for="{ id, to, label, icon, tag } in orgOptions"
          :key="id"
          class="cfp_c-action-list__item"
        >
          <koi-button
            :tag="tag || linkType"
            v-bind="getLinkProps(to)"
            intent="tertiary"
            :data-qa="`link-${label}`"
            class="cfp_c-action-list__link"
          >
            <koi-icon
              v-space.right.xs
              :name="icon"
              variant="duotone"
              size="text-sm"
            />
            {{ $t(label) }}
          </koi-button>
        </li>
      </ul>
    </template>
  </koi-dropdown-card>
</template>

<script lang="ts">
  import Vue from 'vue';
  import { mapGetters } from 'vuex';
  import { Roles } from '@/enums/Roles';
  import { DropdownOptions, orgOptionsConst } from './dropdown-options';

  export default Vue.extend({
    name: 'toc-dropdown',

    data: () => ({
      orgOptions: [] as DropdownOptions[],
      isAdminApp: window.location?.pathname?.startsWith('/admin'),
    }),

    computed: {
      linkType() {
        return this.isAdminApp ? 'router-link' : 'a';
      },
      ...mapGetters('user', {
        currentOrg: 'currentOrg',
        globalProduct: 'globalProduct',
      }),
    },

    watch: {
      currentOrg: {
        immediate: true,
        handler(newVal) {
          this.orgOptions = [...orgOptionsConst(newVal.id)] as DropdownOptions[];
        },
      },
    },

    mounted() {
      // TODO: Remove entire if block. This is a feature flag for https://jira.corp.phishme.com/browse/MH-3424
      if (this.currentOrg?.id !== 'de454f3d-32d1-4caa-bd55-234b671d1c7b') {
        this.orgOptions.splice(1, 1);
      }
      // TODO: Remove down to here
      if (!this.globalProduct?.roleNames?.includes(Roles.reporterStatsReader)) {
        this.orgOptions = this.orgOptions?.filter(
          (orgOption: DropdownOptions) => orgOption.label !== 'graphReport',
        );
      }
    },

    methods: {
      getLinkProps(to: string): Record<string, string> {
        if (this.isAdminApp && to.startsWith('/admin')) {
          return { to };
        }
        return { href: to };
      },
    },
  });
</script>

<i18n src="./translations.json" />
