<!-- (c) 2021 Cofense Inc. -->
<template>
  <koi-form-textarea
    :id="id"
    v-model="localValue"
    :name="name"
    :disabled="disabled"
    :is-invalid="!!errors.length"
  />
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';

  export default Vue.component('cfp-form-textarea-input', {
    name: 'cfp-form-textarea-input',

    props: {
      id: {
        type: String as PropType<string>,
        required: true,
      },

      disabled: Boolean,

      name: {
        type: String as PropType<string>,
        required: true,
      },

      value: {
        type: String as PropType<string>,
        default: '',
      },

      errors: {
        type: Array as PropType<string[]>,
        required: true,
      },
    }, // props

    computed: {
      localValue: {
        get(): string {
          return this.value;
        },
        set(value: string): void {
          this.$emit('input', value);
        },
      },
    }, // computed
  });
</script>
