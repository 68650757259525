<!-- (c) 2022 Cofense Inc. -->
<template>
  <component
    :is="tag"
    v-bind="$attrs"
    role="button"
    tabindex="0"
    class="c-pseudo-button"
    @click="$listeners.click"
    @keyup.enter.prevent="$listeners.click"
    @keydown.space.prevent="$listeners.click"
  >
    <slot />
  </component>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';

  export default Vue.extend({
    name: 'pseudo-button',

    props: {
      tag: {
        default: 'div',
        type: String as PropType<string>,
        validator: (value: string) => ['div', 'span'].includes(value),
      },
    }, // props
  });
</script>
